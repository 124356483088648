import { of, EMPTY, } from 'rxjs';
import { actions, } from '@ezugi/bootstrap';
import { CALL_BETS, } from '@ezugi/constants';

const {
  roundActions: { round, },
} = actions;

export default function handleRoundStatus(socketMessage) {
  const { TimerTimeLeft, TimerTime, roundStartTimestamp, RoundTripStartTime, MessageType, } = socketMessage;
  return ({ videoDelay = 0, }) => {
    const timeLeft = +TimerTimeLeft - videoDelay;
    const totalTime = +TimerTime - videoDelay;

    switch (MessageType) {
    case CALL_BETS: {
      return of(
        round.set({
          roundStatus: CALL_BETS,
          timeLeft: timeLeft < 0 ? 0 : timeLeft,
          totalTime,
          roundStartTimestamp,
          roundTime: RoundTripStartTime,
        })
      );
    }

    default: {
      return EMPTY;
    }
    }
  };
}
