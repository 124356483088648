import { selectors, actions, constants, } from '@ezugi/bootstrap';
import { PLACE_YOUR_BETS, LAST_BETS, } from '@ezugi/constants';
import {
  reduce, contains, clamp, last, map, evolve, all, prop, mapObjIndexed, compose, flatten, values,
} from 'ramda';
import { STATUS, } from '../constants';
import * as BET_TYPES from '../../../../constants/betTypes';
import { INITIAL_STATE, } from '../../../reducers/bets';

import { currentBetsSelector, currentAnteBetSelector, lastBetsSelector, } from '../../../selectors/bets';
import { getTotalBet, } from '.';

const { betActions, } = actions;

// eslint-disable-next-line
const { userBalanceSelector, totalBetSelector, generalConfigSelector, currentLimitsSelector, betsSelector } = selectors;
const {
  dialogActions: { dialog, },
  notificationActions: { notification, },
} = actions;

const { DIALOG: { LOW_BALANCE_DIALOG, } = {}, } = constants;
const RESULT_SEED = { ok: true, valid: true, status: STATUS.VALID, };
const statusPriorityMap = {
  [STATUS.BETTING_NOT_ALLOWED]: 5,
  [STATUS.NOT_ALLOWED]: 4,
  [STATUS.NO_CALL_BALANCE]: 3,
  [STATUS.INVALID_BALANCE]: 3,
  [STATUS.OVER_INDEX_LIMIT]: 2,
  [STATUS.BELOW_INDEX_LIMIT]: 1,
  [STATUS.VALID]: 0,
};

const getSimpleNotificationPayload = ({ status, betAutoAdjust, maxBet, minBet, }) => {
  const payload = (() => {
    switch (status) {
    case STATUS.NOT_ALLOWED:
      return { message: 'notifications.bet_not_allowed', };
    case STATUS.INVALID_BALANCE:
      return { message: 'messages.low_balance_message', };
    case STATUS.NO_CALL_BALANCE:
      return { message: 'messages.no_call_balance', };
    case STATUS.OVER_INDEX_LIMIT:
      return { message: 'notifications.bet_over_index_max_limit_adjusted', variables: { maxIndexLimit: maxBet, }, };
    case STATUS.BELOW_INDEX_LIMIT:
      return betAutoAdjust
        ? {
          autoAdjust: true,
          // eslint-disable-next-line
              event_name: STATUS.BELOW_INDEX_LIMIT,
          message: 'notifications.bet_below_index_min_limit_adjusted',
        }
        : { message: 'notifications.bet_below_index_min_limit', variables: { minIndexLimit: minBet, }, };
    default:
      return null;
    }
  })();
  return payload;
};

const getGroupNotificationPayload = ({ status, }) => {
  const payload = (() => {
    switch (status) {
    case STATUS.NOT_ALLOWED:
      return { message: 'notifications.bet_not_allowed', };
    case STATUS.INVALID_BALANCE:
      return { message: 'messages.low_balance_message', };
    case STATUS.NO_CALL_BALANCE:
      return { message: 'messages.no_call_balance', };
    case STATUS.OVER_INDEX_LIMIT:
      return { message: 'notifications.group_bet_over_max_limits', };
    case STATUS.BELOW_INDEX_LIMIT:
      return { message: 'notifications.group_bet_below_min_limits', };
    default:
      return null;
    }
  })();

  return payload;
};

const pipeValidations = (validations) => (bet, state) => reduce(
  (res, fn) => (!res.valid ? res : fn(res.bet, state)),
  {
    ...RESULT_SEED,
    bet,
  },
  validations
);

export const getBetTypeLimits = (() => {
  const cache = {};
  let currentLimits;

  return (type, state) => {
    if (cache[type]) {
      return cache[type];
    }

    currentLimits = currentLimits || currentLimitsSelector(state);

    const limits = { min: 0, max: 0, };

    switch (type) {
    /* Ante */
    case BET_TYPES.ANTE: {
      limits.min = currentLimits.Min_Bet;
      limits.max = currentLimits.Max_Bet;
      break;
    }
    /* SideBets */
    case BET_TYPES.AA_BONUS: {
      limits.min = currentLimits.Min_SideBet;
      limits.max = currentLimits.Max_SideBet;
      break;
    }

    default:
      break;
    }

    return limits;
  };
})();

export const doubleBet = (state) => mapObjIndexed((b, key) => {
  const { min, } = getBetTypeLimits(key, state);
  const value = b.value * 2;
  const valid = value >= min;
  return { ...b, value, valid, };
});

export const doubleBets = (state) => compose(
  evolve({
    current: doubleBet(state),
    totalBet: (t) => t * 2,
  }),
  betsSelector
)(state);

export const validateRoundStatus = (bet, { round: { roundStatus, }, }) => {
  const ok = contains(roundStatus, [ PLACE_YOUR_BETS, LAST_BETS, ]);
  return {
    ok,
    valid: ok,
    status: ok ? STATUS.VALID : STATUS.BETTING_NOT_ALLOWED,
    bet,
    actions: [],
  };
};

export const validateBalance = (bet, state) => {
  const balance = userBalanceSelector(state);
  const totalBet = totalBetSelector(state);

  const ok = balance - totalBet >= bet.value;
  const status = ok ? STATUS.VALID : STATUS.INVALID_BALANCE;

  return {
    ok,
    valid: ok,
    status,
    bet,
    actions: [ dialog.add({ name: LOW_BALANCE_DIALOG, }), ],
  };
};

export const validateAnte = (bet, state) => {
  const currentAnteBet = currentAnteBetSelector(state);
  const anteBet = bet.value * (bet.type === BET_TYPES.ANTE) + currentAnteBet;

  const balance = userBalanceSelector(state);
  const totalBet = totalBetSelector(state);

  const ok = balance - (totalBet + anteBet * 2) >= bet.value;
  const status = ok ? STATUS.VALID : STATUS.NO_CALL_BALANCE;

  return {
    ok,
    valid: ok,
    status,
    bet,
    actions: [
      notification.add(
        getSimpleNotificationPayload({
          status,
        })
      ),
    ],
  };
};

function validateCallBalance(bets = {}, state) {
  const balance = userBalanceSelector(state);
  const currentAnteBet = currentAnteBetSelector(state);
  const anteBet = currentAnteBet + ((bets[BET_TYPES.ANTE] || {}).value || 0);

  const currentTotalBet = totalBetSelector(state);
  const totalBet = getTotalBet(bets);

  const ok = currentTotalBet + totalBet + (anteBet * 2) <= balance;
  const status = ok ? STATUS.VALID : STATUS.NO_CALL_BALANCE;

  return {
    ok,
    valid: ok,
    status,
    ...(ok
      ? {}
      : {
        actions: [
          notification.add(
            getSimpleNotificationPayload({
              status,
            })
          ),
        ],
      }),
  };
}

export const validateLimits = (bet, state) => {
  const balance = userBalanceSelector(state);
  // bet limits
  const currentBets = currentBetsSelector(state);
  const { min: minBet, max: maxBet, } = getBetTypeLimits(bet.type, state);
  const existingBet = (currentBets[bet.type] || {}).value || 0;

  const { betAutoAdjust, } = generalConfigSelector(state);
  let status = bet.value + existingBet < minBet
    ? STATUS.BELOW_INDEX_LIMIT
    : bet.value + existingBet > maxBet
      ? STATUS.OVER_INDEX_LIMIT
      : STATUS.VALID;

  let ok = status === STATUS.VALID;
  const diff = maxBet - existingBet;

  let value = ok
    ? bet.value
    : clamp(betAutoAdjust ? minBet - existingBet : bet.value > diff ? diff : bet.value, diff, bet.value);

  const totalBet = totalBetSelector(state);

  if (value > balance - totalBet) {
    status = STATUS.INVALID_BALANCE;
    ok = false;
    value = 0;
  }

  const valid = !contains(status, [ STATUS.INVALID_BALANCE, STATUS.BETTING_NOT_ALLOWED, ]);

  const _actions = [
    ...(status === STATUS.INVALID_BALANCE
      ? [ dialog.add({ name: LOW_BALANCE_DIALOG, }), ]
      : [
        notification.add(
          getSimpleNotificationPayload({
            status,
            value,
            betAutoAdjust,
            minBet,
            maxBet,
          })
        ),
      ]),
  ];

  return {
    ok,
    valid,
    status,
    bet: {
      ...(value && {
        ...bet,
        value,
        valid: !(status === STATUS.BELOW_INDEX_LIMIT && !betAutoAdjust && value + existingBet < minBet),
      }),
    },
    actions: _actions,
  };
};

export function validateBetUndo(_, state) {
  const h = [ ...state.bets.history, ];
  h.pop();
  const s = last(h) || INITIAL_STATE;
  const b = userBalanceSelector(state);

  const ok = s.totalBet <= b;

  return ok
    ? { ok, actions: [ betActions.history.apply({ ...s, history: h, }), ], }
    : { ok, actions: [ dialog.add({ name: LOW_BALANCE_DIALOG, }), ], };
}

export const validateBet = pipeValidations([
  // newline
  validateRoundStatus,
  validateBalance,
  validateAnte,
  validateLimits,
]);
const isOk = prop('ok');

export function validateBets(_bets, state) {
  const bets = mapObjIndexed((value, key) => ({
    type: key,
    ...value,
  }))(_bets);

  const results = map((bet) => validateBet(bet, state))(bets);
  const ok = all(isOk, values(results));

  const status = compose(
    reduce((prev, next) => {
      const prevPriority = statusPriorityMap[prev];
      const nextPriority = statusPriorityMap[next];

      return nextPriority > prevPriority ? next : prev;
    }, STATUS.VALID),
    map(prop('status'))
  )(values(results));

  return {
    ok,
    status,
    actions: compose(
      flatten,
      map(({ actions: _actions, }) => _actions),
      values
    )(results),
  };
}

export function validateRebet(_, state) {
  const lb = lastBetsSelector(state);
  const b = userBalanceSelector(state);

  const ok = lb.totalBet <= b;
  const status = ok ? STATUS.VALID : STATUS.INVALID_BALANCE;
  const _actions = ok ? [] : [ dialog.add({ name: LOW_BALANCE_DIALOG, }), ];

  let result = {
    status,
    ok,
    valid: ok,
    actions: _actions,
  };

  if (status === STATUS.VALID) {
    result = validateBets(lb.current, state);
    result.actions = result.ok
      ? [ betActions.bet.apply(lb), ]
      : [
        notification.add(
          getGroupNotificationPayload({
            status: result.status,
          })
        ),
      ];
    result.valid = result.ok;
  }

  if (status === STATUS.VALID) {
    Object.assign(result, validateCallBalance(lb.current, state));
  }

  return result;
}

export function validateDouble(_, state) {
  const current = currentBetsSelector(state);
  const tb = totalBetSelector(state);
  const b = userBalanceSelector(state);

  const status = tb * 2 > b ? STATUS.INVALID_BALANCE : STATUS.VALID;
  const ok = status === STATUS.VALID;
  const _actions = ok ? [] : [ dialog.add({ name: LOW_BALANCE_DIALOG, }), ];

  let result = {
    status,
    ok,
    valid: ok,
    actions: _actions,
  };

  if (status === STATUS.VALID) {
    result = validateBets(current, state);
    result.actions = result.ok
      ? [ betActions.bet.apply(doubleBets(state)), ]
      : [
        notification.add(
          getGroupNotificationPayload({
            status: result.status,
          })
        ),
      ];
    result.valid = result.ok;
  }

  if (status === STATUS.VALID) {
    Object.assign(result, validateCallBalance(current, state));
  }

  return result;
}
