import { of, } from 'rxjs';
import { map, omit, } from 'ramda';
import { LOADING, } from '@ezugi/constants';
import { actions, } from '@ezugi/bootstrap';

import { PLAYER_HAND, DEALER_HAND, COMMUNITY_HAND, } from '../../../constants/strings';
import cardsActions from '../../actions/cards';

const {
  socketActions: { socket, },
  videoActions: { video, },
  roundActions: { round, },
  settingsActions: { settings, },
  betActions: { bet, },
} = actions;

export default function handleInitialData(socketMessage) {
  const {
    VideoStreamData,
    roundId,
    timeStamp,
    RoundTripStartTime,
    DealerCards,
    PlayerCards,
    CommunityCards,
    PlayerHand,
    PlayerHandCards,
    playerBetOfThisRound,
    SideBetsResult,
  } = socketMessage;
  return of(
    ...[
      socket.success(socketMessage),
      settings.init(),
      video.set({ streamData: VideoStreamData, }),
      round.set({
        roundStatus: LOADING,
        roundId,
        roundTime: RoundTripStartTime,
        timestamp: timeStamp,
        firstCardHands: {
          PlayerHand,
          PlayerHandCards,
          SideBetsResult,
          HighlightedCards: PlayerHandCards.length >= 2 ? PlayerHandCards.reduce((acc, { CardName, }) => {
            acc[CardName] = { player: true, };
            return acc;
          }, {}) : undefined,
        },
      }),
      bet.apply({
        current: map((value) => ({ value, valid: true, }), {
          ...omit('SideBets', playerBetOfThisRound),
          ...playerBetOfThisRound.SideBets,
        }),
      }),
      cardsActions.cards.set({
        [DEALER_HAND]: { cards: DealerCards.map((CardName) => ({ CardName, })), },
        [PLAYER_HAND]: { cards: PlayerCards.map((CardName) => ({ CardName, })), },
        [COMMUNITY_HAND]: { cards: CommunityCards.map((CardName) => ({ CardName, })), },
      }),
    ]
  );
}
