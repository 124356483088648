export const PLAYER = 'Player';
export const DEALER = 'Dealer';
export const COMMUNITY = 'Community';
export const PUSH = 'Push';

export const PLAYER_HAND = 'Player';
export const DEALER_HAND = 'Dealer';
export const COMMUNITY_HAND = 'Community';
export const SIDE_BETS = 'sideBets';

export const RESULT_WIN = 'Win';
export const RESULT_LOSE = 'Lose';
export const RESULT_PUSH = 'Push';
export const RESULT_DEALER_DISQUALIFIED = 'DealerDisqualified';

export const HIGH_CARD = 'HighCard';
export const ONE_PAIR = 'OnePair';
export const PAIR_OF_ACES = 'PairOfAces';
export const TWO_PAIRS = 'TwoPairs';
export const MINI_ROYAL = 'MiniRoyal';
export const THREE_OF_KIND = 'ThreeOfKind';
export const STRAIGHT = 'Straight';
export const FLUSH = 'Flush';
export const FULL_HOUSE = 'FullHouse';
export const FOUR_OF_KIND = 'FourOfKind';
export const STRAIGHT_FLUSH = 'StraightFlush';
export const ROYAL_FLUSH = 'RoyalFlush';
