import { of, } from 'rxjs';

import { actions, } from '@ezugi/bootstrap';

const {
  roundActions: { round, },
} = actions;

export default function handleFirstCardHands(socketMessage) {
  const { PlayerHand, PlayerHandCards, SidebetResult, } = socketMessage;
  return of(
    round.set({
      firstCardHands: {
        PlayerHand,
        PlayerHandCards,
        SidebetResult,
        HighlightedCards: PlayerHandCards.reduce((acc, { CardName, }) => {
          acc[CardName] = { player: true, };
          return acc;
        }, {}),
      },
    })
  );
}
