import { actions, } from '@ezugi/bootstrap';

import { ANTE, CALL, AA_BONUS, } from '../../../constants/betTypes';

const {
  gameActions: { game, },
} = actions;


function getAABonusSideBetInfo({ payouts, AABonusHandIndex, }) {
  try {
    const p = Object.values(payouts[AA_BONUS]).find(({ index, }) => index === AABonusHandIndex);
    const info = {
      CardHand: p.name,
      Multiplier: p.payout.split(':')[0],

    };

    return info;
  } catch (e) {
    return {};
  }
}

export default function handleWonBets({ WinningBets, }) {
  return ({ gameResults, payouts, }) => {
    const wonSidebets = Object.entries(WinningBets).reduce(
      (acc, [ betType, value, ]) => ([ ANTE, CALL, ].includes(betType)
        ? []
        : [ ...acc, {
          WinAmount: value,
          betType,
          ...getAABonusSideBetInfo({ payouts, ...gameResults, }),
        }, ]),
      []
    );

    return game.set({
      gameResults: {
        ...gameResults,
        wonSidebets,
        winningBets: WinningBets,
      },
    });
  };
}
