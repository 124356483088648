import { CASINO_HOLDEM_BET_TYPES, } from '@ezugi/constants';

export const {
  // newline
  ANTE,
  CALL,
  AA_BONUS,
} = CASINO_HOLDEM_BET_TYPES;

export const SIDEBETS = {
  AA_BONUS,
};

export const MAIN_BETS = {
  ANTE,
};

export const DECISION = {
  CALL: 'Call',
  FOLD: 'Fold',
  AUTO_FOLD: 'AutoFold',
};

export default { ...CASINO_HOLDEM_BET_TYPES, };
