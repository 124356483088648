/* eslint-disable no-unused-vars */
import {
  map, pluck, filter, flatMap, distinctUntilChanged, skipUntil, take, switchMap, delay,
} from 'rxjs/operators';
import { of, concat, } from 'rxjs';
import { ofType, combineEpics, } from 'redux-observable';
import {
  map as rMap, equals, length, isEmpty, applySpec,
} from 'ramda';
import { actions as bootstrapActions, selectors, } from '@ezugi/bootstrap';
import {
  GAME_RESULT, PLACE_YOUR_BETS, NO_MORE_BETS, CALL_BETS_DECISION, WAIT_FOR_NEXT_GAME,
} from '@ezugi/constants';

import * as BETS from '../../../constants/betTypes';
import { getTotalBet, } from './utils';
import { currentBetsSelector, } from '../../selectors/bets';
import { createPlaceBetRequestPayload, } from './utils/request';
import {
  validateBet, validateBetUndo, validateDouble, validateRebet,
} from './utils/validations';

const { roundActions, betActions, socketActions, } = bootstrapActions;
const { round, } = roundActions;
const { bet, totalBet, history, } = betActions;
const { socket, } = socketActions;

const { userSelector, tableIdSelector, roundIdSelector, } = selectors;

const placeBetEpic = (action$, state$) => action$.pipe(
  ofType(bet.request),
  pluck('payload'),
  map((bets) => validateBet(bets, state$.value)),
  flatMap(({ valid, bet: _bet, ...result }) => {
    const actions = [ ...(valid && _bet && !isEmpty(_bet) ? [ of(bet.set(_bet)), ] : []), ];

    actions.push(...rMap(of, result.actions));

    return concat(...actions);
  })
);

const callDecisionEpic = (action$, state$) => action$.pipe(
  ofType(bet.call, bet.fold),
  switchMap(({ type, }) => state$.pipe(
    take(1),
    map(
      applySpec({
        tableId: tableIdSelector,
        roundId: roundIdSelector,
        user: userSelector,
      })
    ),
    flatMap(({ user, tableId, roundId, }) => concat(
      ...[
        of(
          socket.send({
            TableId: String(tableId),
            ClientId: user.clientId,
            MessageType: 'CallDecision',
            destination: 'player',
            CallDecision: type === String(bet.call) ? BETS.DECISION.CALL : BETS.DECISION.FOLD,
            CurrentPlayerToken: user.currentPlayerToken,
            gameType: 'CasinoHoldem',
            roundId,
          })
        ),
        of(
          round.set({
            roundStatus: CALL_BETS_DECISION,
            CallBetDecision: type === String(bet.call) ? BETS.DECISION.CALL : BETS.DECISION.FOLD,
          })
        ),
        ...(type === String(bet.fold)
          ? [
            of(
              round.set({
                roundStatus: WAIT_FOR_NEXT_GAME,
              })
            ).pipe(delay(500)),
          ]
          : []),
      ]
    ))
  ))
);

const betResetEpic = (action$) => action$.pipe(
  ofType(round.set),
  pluck('payload'),
  filter(({ roundStatus, }) => roundStatus === GAME_RESULT || roundStatus === PLACE_YOUR_BETS),
  flatMap(() => concat(of(bet.reset())), of(history.reset()))
);

const totalBetEpic = (action$, state$) => action$.pipe(
  ofType(bet.set),
  map(() => {
    const currentBets = currentBetsSelector(state$.value);

    return totalBet.set({ value: getTotalBet(currentBets), });
  })
);

const betRequestEpic = (action$, state$) => action$.pipe(
  ofType(round.set),
  pluck('payload', 'roundStatus'),
  distinctUntilChanged(),
  filter(equals(NO_MORE_BETS)),
  skipUntil(
    action$.pipe(
      ofType(round.set),
      pluck('payload', 'roundStatus'),
      distinctUntilChanged(),
      filter(equals(PLACE_YOUR_BETS)),
      take(1)
    )
  ),
  map(() => createPlaceBetRequestPayload(state$.value)),
  filter(length),
  flatMap((_actions) => concat(...rMap(of, _actions), of(betActions.bet.success())))
);

const betUndoEpic = (action$, state$) => action$.pipe(
  ofType(history.pop),
  map(() => validateBetUndo(null, state$.value)),
  flatMap((result) => {
    const actions = rMap(of, result.actions);
    return concat(...actions);
  })
);

const rebetEpic = (action$, state$) => action$.pipe(
  ofType(bet.rebet),
  map(() => validateRebet(null, state$.value)),
  flatMap((result) => {
    const actions = rMap(of, result.actions);
    return concat(...actions);
  })
);

const doubleEpic = (action$, state$) => action$.pipe(
  ofType(bet.double),
  map(() => validateDouble(null, state$.value)),
  flatMap((result) => {
    const actions = rMap(of, result.actions);
    return concat(...actions);
  })
);

export default combineEpics(
  placeBetEpic,
  callDecisionEpic,
  totalBetEpic,
  betResetEpic,
  betRequestEpic,
  betUndoEpic,
  doubleEpic,
  rebetEpic
);
