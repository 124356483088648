import { ofType, combineEpics, } from 'redux-observable';
import {
  pluck, filter, flatMap, tap, ignoreElements, take, switchMap, map,
} from 'rxjs/operators';
import { applySpec, } from 'ramda';
import { actions, selectors, socket$, } from '@ezugi/bootstrap';
import {
  INITIAL_DATA,
  PLACE_YOUR_BETS,
  GAME_RESULT,
  CARD_MESSAGE,
  CALL_BETS,
  CALL_BETS_DECISION,
  FIRST_CARD_HANDS,
  WON_BETS,
} from '@ezugi/constants';
import { Card, } from '@ezugi/primitives';

import { DECISION, } from '../../../constants/betTypes';

import { firstCardHandsSelector, } from '../../selectors/round';
import { gameResultsSelector, } from '../../selectors/game';

import handleInitialData from './handleInitialData';
import handleGameResult from './handleGameResult';
import handleCardMessage from './handleCardMessage';
import handleRoundStatus from './handleRoundStatus';
import handlePlaceYourBets from './handlePlaceYourBets';
import handleFirstCardHands from './handleFirstCardHands';
import handleWonBets from './handleWonBets';
import handleAutoFold from './handleAutoFold';

const {
  socketActions: { socket, },
  videoActions: { video, },
} = actions;
const { videoDelaySelector, payoutsSelector, } = selectors;
function initialDataEpic(action$) {
  return action$.pipe(
    ofType(socket.message),
    pluck('payload'),
    filter(({ MessageType, }) => MessageType === INITIAL_DATA),
    flatMap(handleInitialData)
  );
}

function roundStatusEpic(action$, state$) {
  return action$.pipe(
    ofType(socket.message),
    pluck('payload'),
    filter(({ MessageType, }) => [ CALL_BETS, ].includes(MessageType)),
    switchMap((payload) => state$.pipe(
      take(1),
      map((state) => ({
        videoDelay: videoDelaySelector(state),
        firstCardHands: firstCardHandsSelector(state),
      })),
      flatMap(handleRoundStatus(payload))
    ))
  );
}
function autoFoldEpic(action$) {
  return action$.pipe(
    ofType(socket.message),
    pluck('payload'),
    filter((m) => [ CALL_BETS_DECISION, ].includes(m.MessageType) && m.CallBetDecision === DECISION.AUTO_FOLD),
    flatMap(handleAutoFold)
  );
}

function gameResultEpic(action$) {
  return action$.pipe(
    ofType(socket.message),
    pluck('payload'),
    filter(({ MessageType, }) => MessageType === GAME_RESULT),
    flatMap(handleGameResult)
  );
}
function wonBetsEpic(action$, state$) {
  return action$.pipe(
    ofType(socket.message),
    pluck('payload'),
    filter(({ MessageType, }) => MessageType === WON_BETS),
    switchMap((payload) => state$.pipe(
      map(applySpec({ gameResults: gameResultsSelector, payouts: payoutsSelector, })),
      map(handleWonBets(payload)),
      take(1)
    ))
  );
}
function firstCardHandsEpic(action$) {
  return action$.pipe(
    ofType(socket.message),
    pluck('payload'),
    filter(({ MessageType, }) => MessageType === FIRST_CARD_HANDS),
    flatMap(handleFirstCardHands)
  );
}
function placeYourBetsEpic(action$) {
  return action$.pipe(
    ofType(socket.message),
    pluck('payload'),
    filter(({ MessageType, }) => MessageType === PLACE_YOUR_BETS),
    flatMap(handlePlaceYourBets)
  );
}

function cardMessageEpic(action$, state$) {
  return action$.pipe(
    ofType(socket.message),
    pluck('payload'),
    filter(({ MessageType, }) => MessageType === CARD_MESSAGE),
    flatMap((socketMessage) => handleCardMessage(socketMessage, state$.value))
  );
}

function preloadCardEpic(action$) {
  return action$.pipe(
    ofType(video.set),
    take(1),
    pluck('payload', 'cdnList', 'videoDelay'),
    switchMap((videoDelay) => (+videoDelay
      ? socket$.pipe(
        filter(({ MessageType, }) => MessageType === CARD_MESSAGE),
        pluck('CardName'),
        tap(Card.preload)
      )
      : socket$.pipe(
        take(1),
        tap(() => {
          Card.preloadAll();
        })
      ))),
    ignoreElements()
  );
}

export default combineEpics(
  initialDataEpic,
  roundStatusEpic,
  firstCardHandsEpic,
  gameResultEpic,
  wonBetsEpic, // must be after gameResultEpic
  placeYourBetsEpic,
  cardMessageEpic,
  preloadCardEpic,
  autoFoldEpic
);
