import { when, assoc, evolve, } from 'ramda';
import {
  HIGH_CARD,
  ONE_PAIR,
  THREE_OF_KIND,
  FOUR_OF_KIND,
  TWO_PAIRS,
  STRAIGHT,
  FLUSH,
  FULL_HOUSE,
  STRAIGHT_FLUSH,
  ROYAL_FLUSH,
} from '../../../constants/strings';
import * as BET_TYPES from '../../../constants/betTypes';

export const payoutsIndexMap = {
  0: HIGH_CARD,
  1: ONE_PAIR,
  2: TWO_PAIRS,
  3: THREE_OF_KIND,
  4: STRAIGHT,
  5: FLUSH,
  6: FULL_HOUSE,
  7: FOUR_OF_KIND,
  8: STRAIGHT_FLUSH,
  9: ROYAL_FLUSH,
};

const { AA_BONUS, ANTE, } = BET_TYPES;

function hasPayout(payouts, payoutOption) {
  return () => !!+payouts[payoutOption];
}

function getPayoutObj(p, payouts, payoutOption) {
  return {
    index: +p.IndexID,
    strength: +payouts[payoutOption],
    payout: `${payouts[payoutOption]}:1`,
    name: payoutsIndexMap[p.IndexID],
  };
}

export function formatPayouts(list = []) {
  return list.reduce(
    (acc, p) => {
      const payouts = JSON.parse(p.payout);

      return evolve({
        [ANTE]: when(
          hasPayout(payouts, 'option1'),
          assoc(payoutsIndexMap[p.IndexID], getPayoutObj(p, payouts, 'option1'))
        ),
        [AA_BONUS]: when(
          hasPayout(payouts, 'option2'),
          assoc(payoutsIndexMap[p.IndexID], getPayoutObj(p, payouts, 'option2'))
        ),
      })(acc);
    },
    {
      [ANTE]: {},
      [AA_BONUS]: {
        // TODO: Maybe this should come from the BO
        PairOfAces: { index: 1, strength: 7, payout: '7:1', name: 'PairOfAces', },
      },
    }
  );
}
