import { of, concat, } from 'rxjs';

import cardsActions from '../../actions/cards';
import { playerCardsSelector, dealerCardsSelector, communityCardsSelector, } from '../../selectors/cards';
import { PLAYER_HAND, DEALER_HAND, COMMUNITY_HAND, } from '../../../constants/strings';

const cardSelectorMap = {
  [PLAYER_HAND]: playerCardsSelector,
  [DEALER_HAND]: dealerCardsSelector,
  [COMMUNITY_HAND]: communityCardsSelector,
};

function updateCards(card) {
  const idx = this.findIndex((c) => c.CardName === 'ww');
  if (idx > -1 && card.CardName !== 'ww') {
    return [ ...this.slice(0, idx), card, ...this.slice(idx + 1), ];
  }

  return [ ...this, card, ];
}

export default function handleCardMessage(socketMessage, state) {
  const { CardHand, CardName, } = socketMessage;


  const { cards, } = cardSelectorMap[CardHand](state);

  return concat(
    of(
      cardsActions.cards.set({
        [CardHand]: { cards: updateCards.call(cards, { CardName, }), },
      })
    )
  );
}
